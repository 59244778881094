.background-container {
    width: 100%;
    height: 100vh; /* Adjust based on your layout */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 4s ease-in-out; /* Default smooth fade-in/out transition */
  }
  
  .background-container.fading {
    opacity: 0; /* Makes the container invisible during the fade-out */
  }