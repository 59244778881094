.container{
    position: relative;
    display: inline-block; 
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .middleonimage{
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
  }

  .container:hover .image {
    opacity: 0.3;
  }
  
  .container:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #fff474;
    color: #4f504b;
    font-size: 25px;
    padding: 16px 32px;
    font-family: "Edwin";
    border-radius: 25px;
    margin-top: 5px;
  }

  /* Small screens (phones) */
@media (max-width: 576px) {
  .text {
    background-color: #fff474;
    color: #4f504b;
    font-size: 20px;
    padding: 16px 32px;
    font-family: "Edwin";
    border-radius: 25px;
    margin-top: 5px;

  }
}

/* Medium screens (tablets) */
@media (min-width: 577px) and (max-width: 992px) {
  .text {
    background-color: #fff474;
    color: #4f504b;
    font-size: 22px;
    padding: 16px 32px;
    font-family: "Edwin";
    border-radius: 25px;
    margin-top: 5px;

  }
}

/* Large screens (desktops) */
@media (min-width: 993px) {
  .text {
    background-color: #fff474;
    color: #4f504b;
    font-size: 25px;
    padding: 16px 32px;
    font-family: "Edwin";
    border-radius: 25px;
    margin-top: 5px;
  }
}